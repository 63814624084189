.nav {
    .nav-item{
        .nav-link:hover,
        .nav-link:focus{
            background-color: transparent;
        }
    }

}
.navbar{
    border: $none;
    font-size: $font-size-navbar;
    border-radius: 0;
    min-height: 50px;
    padding-left: 0;
    background-color: $white-navbar;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .navbar-brand {
        font-weight: 400;
        margin: 5px 0px;
        font-size: 20px;
        color: $default-color;

        &:hover{
            color: #5e5e5e;
        }
    }
    .navbar-toggler{
        vertical-align: middle;
        outline: 0;
        cursor: pointer;

        &.navbar-toggler-left{
            position: relative;
            left: 0;
            padding-left: 0;
        }

        &.navbar-toggler-right{
            padding-right: 0;
            top: 18px;
        }

        & .navbar-toggler-bar{
            width: 3px;
            height: 3px;
            border-radius: 50%;
            margin: 0 auto;
        }
        .burger-lines{
            display: block;
            position: relative;
            background-color: #888;
            width: 24px;
            height: 2px;
            border-radius: 1px;
            margin: 4px auto;
        }
    }


    .navbar-nav{
        .nav-item{
            .nav-link{
                color: $default-color;
                padding: 0;
                padding-left: 2rem;
                margin: $navbar-margin-a;
                position: relative;

                &.btn{
                    margin: $navbar-margin-a-btn;
                    padding: $padding-base-vertical $padding-base-horizontal;
                }

                &.btn-round{
                    margin: $navbar-margin-a-btn-round;
                }

                &[class^="fa"],
                &[class^="fas"],
                &[class^="far"],
                &[class^="fal"],
                &[class^="fad"],
                &[class^="fab"]{
                    font-size: $font-size-large + 1;
                    position: relative;
                    line-height: 40px;
                    top: 1px;
                }

                &:hover{
                    color: $info-color;
                }
            }

            .searchInput {
                font-size: 13px;
                color: #555555;
                min-width: 250px;
                border-radius: 20px;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
            }

            .far.fa-bell {font-size: 20px;}

            .dropdown-menu{
                border-radius: 10px;
                left: auto;
                right: 0;
                margin-top: 6px;
                background: #f8f9fa;
                border-radius: 0.3rem;
                box-shadow: none;
                &::before {
                    border-bottom: 7px solid rgba(0, 0, 0, 0.2);
                    border-left: 7px solid rgba(0, 0, 0, 0);
                    border-right: 7px solid rgba(0, 0, 0, 0);
                    content: "";
                    display: inline-block;
                    position: absolute;
                    left: auto;
                    top: -8px;
                    right: 7px;
                }
                &:after {
                    border-bottom: 7px solid #FFF;
                    border-left: 7px solid rgba(0, 0, 0, 0);
                    border-right: 7px solid rgba(0, 0, 0, 0);
                    content: "";
                    display: inline-block;
                    position: absolute;
                    left: auto;
                    top: -7px;
                    right: 7px;
                }

                .dropdown-item{
                    &:first-child{
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                    &:last-child{
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
                }

                .divider{
                    height: 1px;
                    margin: 5px 0;
                    overflow: hidden;
                    background-color: #e5e5e5;
                }
            }
        }

        .notification {
            position: absolute;
            background-color: #d80713;
            text-align: center;
            min-width: 15px;
            height: 15px;
            line-height: 16px;
            padding: 0 5px;
            font-weight: 500;
            font-size: 9px;
            color: #FFFFFF;
            top: -8px;
            left: auto;
            right: -12px;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            -ms-border-radius: 2px;
            -o-border-radius: 2px;
            border-radius: 2px;
        }

        .dropdown-toggle:after{
            display: inline-block;
            width: 0;
            display: none;
            height: 0;
            margin-left: 5px;
            margin-top: 20px;
            vertical-align: middle;
            border-top: 4px dashed;
            border-top: 4px solid\9;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
        }
    }
    .btn{
       margin: $navbar-margin-btn;
       font-size: $font-size-base;
       width: 35px;
       height: 35px;
    }
    .btn-simple{
        font-size: $font-size-medium;
    }

    &.fixed{
        width: calc(100% - #{$sidebar-width});
        right: 0;
        left: auto;
        border-radius: 0;
    }

    .nc-icon{
        font-weight: 700;
        margin-top: 10px;
    }
}

.navbar-transparent, [class*="navbar-ct"]{
    .navbar-brand{
        color: $white-color;
        @include opacity(.9);

        &:focus,
        &:hover{
            background-color: transparent;
            @include opacity(1);
            color: $white-color;
        }
    }

    .navbar-nav{
        .nav-item{
            .nav-link:not(.btn){
                color: $white-color;
                border-color: $white-color;
                @include opacity(0.8);
            }
        }

        .active,
        .nav-item{
            .nav-link:not(.btn),
            .nav-link:hover:not(.btn),
            .nav-link:focus:not(.btn) {
                background-color: transparent;
                border-radius: 3px;
                color: $white-color;
                @include opacity(1);
            }
        }

        .nav .nav-item .nav-link.btn:hover{
            background-color: transparent;
        }

        .show{
            .nav-link,
            .nav-link:hover,
            .nav-link:focus{
                background-color: transparent;
                color: $white-color;
                @include opacity(1);
            }
        }
    }

    .btn-default{
        color: $white-color;
        border-color: $white-color;
    }
    .btn-default.btn-fill{
        color: $dark-gray;
        background-color: $white-color;
        @include opacity(.9);
    }
    .btn-default.btn-fill:hover,
    .btn-default.btn-fill:focus,
    .btn-default.btn-fill:active,
    .btn-default.btn-fill.active,
    .show .dropdown-toggle.btn-fill.btn-default{
        border-color: $white-color;
        @include opacity(1);
    }

}
.navbar-transparent{
    .dropdown-menu .divider{
        background-color: rgba($white-color,.2);
    }
}


.navbar-default {
    background-color: $white-navbar;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .navbar-nav{
        .nav-item{
            .nav-link:not(.btn){
                color: $dark-gray;
            }
        }

        .active .nav-link,
        .active .nav-link:not(.btn):hover,
        .active .nav-link:not(.btn):focus,
        .nav-item .nav-link:not(.btn):hover,
        .nav-item .nav-link:not(.btn):focus{
            background-color: transparent;
            border-radius: 3px;
            color: $info-color;
            @include opacity(1);
        }

        .show{
            .nav-link,
            .nav-link:hover,
            .nav-link:focus{
                background-color: transparent;
                color: $info-color;
            }
        }


        .navbar-toggle:hover,.navbar-toggle:focus {
            background-color: transparent;
        }

    }

    &:not(.navbar-transparent) .btn-default:hover{
        color: $info-color;
        border-color: $info-color;
    }
    &:not(.navbar-transparent) .btn-neutral,
    &:not(.navbar-transparent) .btn-neutral:hover,
    &:not(.navbar-transparent) .btn-neutral:active{
            color: $dark-gray;
        }
}

/*      Navbar with icons            */

.navbar-icons{
    &.navbar .navbar-brand{
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .navbar-nav{
        .nav-item{
            .nav-link{
                text-align: center;
                padding: $navbar-padding-a-icons;
                margin: $navbar-margin-a-icons;
            }
        }

        [class^="pe"] {
            font-size: 30px;
            position: relative;
        }
        p {
            margin: 3px 0 0;
        }
    }
}

.navbar-form{
   @include box-shadow(none);
   .form-control{
        @include light-form();
        height: 22px;
        font-size: $font-size-navbar;
        line-height: $line-height-general;
        color: $light-gray;
    }
    .navbar-transparent & .form-control,
    [class*="navbar-ct"] & .form-control{
        color: $white-color;
        border: $none;
        border-bottom: 1px solid rgba($white-color,.6);
    }

}

.navbar-ct-blue{
    @include navbar-color($blue-navbar);
}
.navbar-ct-azure{
    @include navbar-color($azure-navbar);
}
.navbar-ct-green{
    @include navbar-color($green-navbar);
}
.navbar-ct-orange{
    @include navbar-color($orange-navbar);
}
.navbar-ct-red{
    @include navbar-color($red-navbar);
}

.navbar-transparent{
    padding-top: 15px;
    background-color: transparent;
    border-bottom: 1px solid transparent;
}

.navbar-toggle{
    margin-top: 19px;
    margin-bottom: 19px;
    border: $none;

    .icon-bar {
        background-color: $white-color;
    }
     .navbar-collapse,
     .navbar-form {
        border-color: transparent;
    }

    &.navbar-default .navbar-toggle:hover,
    &.navbar-default .navbar-toggle:focus {
        background-color: transparent;
    }
}
