#root {
  height: 100vh
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

img {
  max-width: 100%;
}

.places-buttons .btn {
  margin-bottom: 30px
}

.sidebar .nav>li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.sidebar .nav>li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #FFFFFF;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.footer-dropdown {
  top: -120px !important;
}

.footer-dropdown:before,
.footer-dropdown:after {
  top: 300px !important;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  opacity: 1;
}

.btn-social {
  opacity: 0.85;
  padding: 8px 9px;
}

.btn-social .fa {
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
}

.btn-social.btn-round {
  padding: 9px 10px;
}

.btn-social.btn-simple {
  padding: 9px 5px;
  font-size: 16px;
}

.btn-social.btn-simple .fa {
  font-size: 20px;
  position: relative;
  top: -2px;
  width: 24px;
}

.btn-facebook {
  border-color: #3b5998;
  color: #3b5998;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open>.btn-facebook.dropdown-toggle {
  background-color: transparent;
  color: #3b5998;
  border-color: #3b5998;
  opacity: 1;
}

.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
  background-color: transparent;
  border-color: #3b5998;
}

.btn-facebook.btn-fill {
  color: #ffffff;
  background-color: #3b5998;
  opacity: 0.9;
}

.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open>.btn-facebook.btn-fill.dropdown-toggle {
  background-color: #3b5998;
  color: #ffffff;
  opacity: 1;
}

.btn-twitter {
  border-color: #55acee;
  color: #55acee;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open>.btn-twitter.dropdown-toggle {
  background-color: transparent;
  color: #55acee;
  border-color: #55acee;
  opacity: 1;
}

.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
  background-color: transparent;
  border-color: #55acee;
}

.btn-twitter.btn-fill {
  color: #ffffff;
  background-color: #55acee;
  opacity: 0.9;
}

.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open>.btn-twitter.btn-fill.dropdown-toggle {
  background-color: #55acee;
  color: #ffffff;
  opacity: 1;
}

.places-buttons .btn {
  margin-bottom: 30px
}

.sidebar .nav>li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.sidebar .nav>li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #FFFFFF;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #5d5d5d;
}

.react-toggle--checked .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  outline: 0;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #1fc6ea;
}

.github-btn {
  font: bold 11px/14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: 20px;
  overflow: hidden;
}

.gh-btn,
.gh-count,
.gh-ico {
  float: left;
}

.gh-btn,
.gh-count {
  padding: 2px 5px 2px 4px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}

.gh-btn {
  background-color: #eee;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fcfcfc), color-stop(100%, #eee));
  background-image: -webkit-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -moz-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -ms-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -o-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
}

.gh-btn:hover,
.gh-btn:focus {
  text-decoration: none;
  background-color: #ddd;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #eee), color-stop(100%, #ddd));
  background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -moz-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -ms-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -o-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
  border-color: #ccc;
}

.gh-btn:active {
  background-image: none;
  background-color: #dcdcdc;
  border-color: #b5b5b5;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

.gh-ico {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.gh-count {
  position: relative;
  display: none;
  /* hidden to start */
  margin-left: 4px;
  background-color: #fafafa;
  border: 1px solid #d4d4d4;
}

.gh-count:hover,
.gh-count:focus {
  color: #4183C4;
}

.gh-count:before,
.gh-count:after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.gh-count:before {
  top: 50%;
  left: -3px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: #fafafa;
}

.gh-count:after {
  top: 50%;
  left: -4px;
  z-index: -1;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #d4d4d4;
}

.github-btn-large {
  height: 30px;
}

.github-btn-large .gh-btn,
.github-btn-large .gh-count {
  padding: 3px 10px 3px 8px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
}

.github-btn-large .gh-ico {
  width: 20px;
  height: 20px;
}

.github-btn-large .gh-count {
  margin-left: 6px;
}

.github-btn-large .gh-count:before {
  left: -5px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
}

.github-btn-large .gh-count:after {
  left: -6px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
}

.typography-line span {
  bottom: 10px;
  color: #9A9A9A;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  left: 5px;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.typography-line {
  padding-left: 15%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.headTag {
  background: #ffffff;
  padding: 0.5rem 1rem;
  margin: -30px 0 15px;
  border-bottom: 1px solid #ddd;
}

.headTag h3 {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  color: #000;
}

.headTag h3 span {
  font-size: 15px;
  margin: 0;
  font-style: italic;
  display: block;
  font-weight: 200;
  color: #555555;
  line-height: 25px;
}

.projectTitle h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

.projectTitle h3 small {
  font-size: 90%;
}

.manageActions .list-group {
  flex-direction: row;
}

.manageActions .list-group-item {
  padding: 0.2rem 0.4rem;
  font-size: 15px;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  margin-left: 0.25rem;
  cursor: pointer;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
}

.manageActions .list-group-item:hover {
  background: #ddd;
}

.projectTitle h3 a {
  color: #6f8cff;
}

.hLine {
  position: relative;
}

.hLine:after {
  position: absolute;
  left: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: #dddddd45;
  content: '';
  margin: auto;
  bottom: -100%;
  z-index: 0;
}

.lastDiv .hLine:after {
  display: none;
}

.projectIcon {
  position: relative;
  min-width: 35px;
  min-height: 35px;
}

.projectIcon .badge {
  width: 12px;
  height: 12px;
  display: inline-block;
  padding: 0;
  position: absolute;
  right: 2px;
  bottom: 4px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border: 2px solid #ffffff;
}

.headTag .btn-primary {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  background: none;
}

.headTag .btn-primary span {
  color: #31cab9;
  margin-right: 0.5rem;
  font-size: 24px;
  position: relative;
}

.headTag .btn-primary span:before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  width: 25px;
  height: 25px;
  border-style: solid;
  border-width: 0 0 3px 3px;
  border-color: #31cab9;
  transform: translate(-6px, 0px);
  -webkit-transform: translate(-6px, 0px);
  -moz-transform: translate(-6px, 0px);
  -ms-transform: translate(-6px, 0px);
  -o-transform: translate(-6px, 0px);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.projectTable .table thead>tr>th {
  font-weight: 600;
  color: #212121;
  text-transform: capitalize;
  font-size: 16px;
  padding: 1rem;
}

.card .table tbody td:first-child,
.card .table thead th:first-child {
  padding-left: 0;
  min-width: 270px;
}

.projectTable .table thead>tr>th:nth-child(3) {
  min-width: 170px;
}

.projectTable .table tbody tr td {
  padding: 1rem;
}

.projectTable .table tbody tr td .media img {
  max-width: 75px;
}
.projectTable .table tbody tr td, .projectTable .table tbody tr td p {
	font-size: 13px;
	margin: 0;
	vertical-align: middle;
	color: #393939;
	width: 261px;
}
.projectTable .table tbody tr td h5 {
  font-size: 15px;
  color: #212121;
  font-weight: 600;
  margin: 0;
}

.projectTable .table td h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.projectTable .table td .text-green {
  color: #135846;
}

.projectTable .table td .text-red {
  color: #8d1111;
}

.projectTable .table td .text-due {
  color: #9f9023;
}

.projectTable .table td .text-active {
  color: #5ad07a;
}

.projectTable .table td .text-inactive {
  color: #d05a6c;
}

.projectTable button#dropdown-basic {
  border: 0;
  background: none;
  padding: 0;
  color: #212121;
  margin: 0;
}

.projectTable .btn-success:not(:disabled):not(.disabled).active,
.projectTable .btn-success:not(:disabled):not(.disabled):active,
.projectTable .show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e3400;
  border-color: #1c743000;
}

.projectTable .dropdown-menu {
  border-radius: 0;
  box-shadow: none;
}

.projectTable .dropdown-menu[x-placement^=bottom],
.projectTable .dropdown-menu[x-placement^=left],
.projectTable .dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
  right: 0;
  bottom: auto;
  left: auto;
}

.projectTable .dropdown-menu .dropdown-item {
  padding: 10px;
  color: #333333;
  font-size: 13px;
  border-bottom: 1px solid #77777730;
}

.projectTable .dropdown-menu .dropdown-item:last-child {
  border-bottom: 0;
}

.projectTable .dropdown .dropdown-toggle::after {
  display: none;
}

.projectTable .card-body {
  padding: 8px 15px 15px 15px;
}

.usersTable .table tbody td:first-child,
.usersTable .table thead th:first-child {
  padding-left: 0;
  min-width: 175px;
}

.usersTable .table thead>tr>th:nth-child(3) {
  min-width: 0;
}

.usersTable .table thead>tr>.minWidth1 {
  min-width: 135px;
}

.usersTable .table thead>tr>.minWidth2 {
  min-width: 160px;
}

.usersTable .table thead>tr>.minWidth3 {
  min-width: 195px;
}

.usersTable .btn {
  font-weight: 500;
  border: 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.usersTable .btn-success {
  background: #e8ffee;
  color: #5ad07a;
}

.usersTable .btn-secondary {
  background: #d8d8d8;
  color: #575757;
}

.usersTable .btn-primary {
  background-color: skyblue;
}

.imageUpload {
  cursor: pointer;
  position: relative;
  width: 180px;
  height: 180px;
  overflow: hidden;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
}

.imageUpload img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imageUpload .badge {
  width: 22px;
  height: 22px;
  display: inline-block;
  padding: 0;
  position: absolute;
  right: 18px;
  bottom: 18px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border: 2px solid #ffffff;
}

.awardSec .badge {
  padding: 1rem 2rem;
  background: #31cab9;
  color: #ffffff;
  margin-right: 1rem;
  font-size: 15px;
  font-weight: 500;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}

.awardSec .badge i {
  font-size: 1.8rem;
  vertical-align: middle;
  margin-right: 0.5rem;
}

/*-=-=-=
30-03-2021
-=-=-=-=-*/

.form-detail .form-group {
  color: black;
}

.form-detail .form-group p span {
  display: inline-block;
  color: #212121;
  min-width: 150px;
}

.form-detail .form-group .fa-calendar {
  font-size: 17px;
  border: 1px solid;
  width: 35px;
  height: 35px;
  line-height: 32px;
  text-align: center;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}


.form-detail .form-check .form-check-label {
  padding-left: 7px;
  line-height: 17px;
  font-style: italic;
  font-weight: 400;
  color: black;
}

.form-detail .form-check {
  margin-right: 1rem;
  flex: 1 1;
}

.form-detail .form-check input[type="checkbox"],
.form-detail .form-check-radio input[type="radio"] {
  opacity: 1;
  vertical-align: top;
  position: inherit;
  visibility: visible;
}

.form-detail .media-body {
  flex: none;
}

.form-detail .projectTitle h3 {
  font-size: 14px;
  min-width: 100px;
  color: #333333;
}

.form-detail .projectTitle img {
    max-width: 35px;
    border-radius: 100px;
    height: 35px;
    width: 35px;
    object-fit: cover;
}

.form-detail .media {
  border-color: #dddddd;
  padding: 0.5rem 0;
  border-style: solid;
  border-width: 1px 0 0 0;
}

.initialBadge {
  position: relative;
  background: #0bd8a1;
  color: #fff;
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 100px;
  text-align: center;
  line-height: 35px;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #42d8a1;
  background-color: transparent;
  border-color: #31cab9;
}

.btn-primary {
  border-color: #31cab9;
  color: #ffffff;
  background: #31cab9;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background-color: transparent;
  color: #31cab9;
  border-color: #31cab9;
}

.main-translator-panel {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  float: right;
  width: 100%;
  min-height: 100%;
}

.pagination {
  border-radius: 0;
  justify-content: flex-end;
  padding: 0;
  margin-top: 1rem;
}

.pagination>li {
  display: inline-block;
  padding-left: 0;
}

.pagination>li {
  list-style: none;
  border: 0;
  margin: 0 0.1rem
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 8px 15px;
  line-height: 1.42857143;
  text-decoration: none;
  background: #ffffff;
  color: #000000;
  font-size: 13px;
  border: 1px solid #ffffff;
  margin-left: 0px;
}

.pagination>li.active>a, .pagination>li>a:hover {
  color: #fff;
  background: #31cab9;
  border: 1px solid #31cab9
}

.pagination>li.previous a, .pagination>li.next a {
  border: 1px solid #31cab9
}

.pagination>li.previous {
  margin-right: 5px;
}

.pagination>li.next {
  margin-left: 5px;
}

.SpreadsheetGridContainer div,
.SpreadsheetGridContainer input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 28rem;
}

.fileActions .list-group-item svg {
  margin-right: 0.2rem;
}

.card .fileActions label {
  font-size: 100%;
  cursor: pointer;
  color: inherit;
  margin-bottom: 0;
  text-transform: capitalize;
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
  display: block;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
}

.navbar .navbar-nav .nav-item .nav-link {
  margin: 0;
}

.navbar .btn-mark,
.navbar .btn-clear {
  background: #000;
  color: #fff;
  padding: 10px 12px;
  font-size: 12px;
  border: 0;
}

.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
  color: #000 !important;
  text-decoration: none;
  background-color: #f8f9fa;
}

.navbar .dropdown-menu .dropdown-item {

  text-align: left;
  padding: .5rem 1em;
  color: #272727;
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  font-size: 13px;
  line-height: 17px;
}

.message {
  text-align: left;
  padding: .5rem 1em;
  color: #272727;
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  font-size: 13px;
  line-height: 17px;
}

.navbar .navbar-nav .nav-item .dropdown-menu {
  border-radius: 10px;
  left: auto;
  right: 0;
  margin-top: 6px !important;
  background: #f8f9fa;
  border-radius: 0.3rem;
  box-shadow: none;
  width: 15rem;
}

.status_profile {
  display: block;
  background-color: #3472F7;
  color: white;
  height: 2rem;
  padding-left: 3rem;
  border-radius: 1rem;
  padding-top: 0.1rem;
}

.status_profile1 {
  display: block;
  background-color: #3472F7;
  color: white;
  height: 2rem;
  padding-left: 1rem;
  border-radius: 1rem;
  padding-top: 0.1rem;
}

svg {
  overflow: hidden;
  vertical-align: middle;
  width: 13rem;
}

.list-group-item svg {
  overflow: hidden;
  vertical-align: middle;
  width: 1rem;
}

/* endofmedia */
.rs-timeline-item-tail,
.rs-timeline-item-dot {
  display: none;
}

.rs-timeline-item {
  margin-top: 1rem;
}

.translator-section {
  display: flex;
  align-items: center;
  position: relative;
}

.translator-section h3 {
  font-size: 14px;
  margin: 0;
}

.translator-section span.badge {
  position: absolute;
  display: block !important;
  width: 10px;
  height: 10px;
  padding: 0;
  position: absolute;
  right: 2px;
  bottom: 4px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border: 2px solid #ffffff;
}

.translator-section a {
  display: flex;
  align-items: center
}


.online.badge {
  width: 12px;
  height: 12px;
  display: inline-block;
  padding: 0;
  position: absolute;
  right: 2px;
  bottom: 0px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border: 2px solid #ffffff;
}

.online1.badge {
  width: 12px;
  height: 12px;
  display: inline-block;
  padding: 0;
  position: absolute;
  /* right: 2px; */
  bottom: 15px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border: 2px solid #ffffff;
  left: 5.8rem;
}

.no-icon img {
  border-radius: 50%;
}

.dhx_message-container {
  display: none
}
.sidebar-wrapper .profile-sec {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 13px;
  padding: 1rem 2rem
}
.no-icon .online1.badge {
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  margin-right: 5px;
}
.no-icon .sidebar-icon, .no-icon img {
  float: left;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 2.5rem;
  margin-right: 1rem;
}
#basic-navbar-nav.navbar-collapse {
  flex-basis: auto;
  flex-grow: 0;
  align-items: center;
}

.project-moniter {
  width: 50%;
  text-align: center;
  margin-bottom: 10px;
}

.project-moniter p {
  color: #4f81bd;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.project-moniter span {
  font-size: 12px;
}

.montier-section p {
  color: #c0504d;
}

.project-section {
  display: contents;
}

.card label {
  font-size: 13px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #212121;
}

.fa-check-circle:before {
  content: "\f058";
  color: black;
}



.dhx-spreadsheet-grid .dhx_grid-cell .dhx_lock_icon,
.dhx-spreadsheet-grid .dhx_grid-cell .dxi-key:before {
  
  display: none !important;
}
.navbar-expand-lg .navbar-nav {
  align-items: center
}

.media-body {
  width: 78%;
}

.heading-text {
  width: 188px;
  text-align: right;
}

.canvasjs-chart-credit {
  display: none;
}

.siteLogo {
  color: #fff;
  text-align: center;
  padding: 0.3rem 0 0 0;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  background: #2B333B;
  margin-left: 4rem;
}

.siteLogo span {
  display: block;
  font-size: 2rem;
  font-weight: 700;
}

.sidebar .sidebar-wrapper,
body>.navbar-collapse .sidebar-wrapper {
  position: relative;
  max-height: calc(100vh - 75px);
  min-height: 100%;
  overflow: auto;
  width: 260px;
  z-index: 4;
  padding-bottom: 100px;
  background-color: #2B333B;
}

.sidebar .logo,
body>.navbar-collapse .logo {
  padding: 0px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

#spreadsheet {
  max-width: 100%;
  max-height: 100%;
}
.navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 1px;
}

.dhx_widget .dhx_toolbar-button__icon {
    line-height: 0;
}

.main-panel.expandPanel {
  z-index: 111;
  width: 100%;
  transition: width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -ms-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
}

.btn-primary img {
  max-width: 24px;
  vertical-align: text-bottom;
  -webkit-filter: invert(1);
  filter: invert(1);
}

.btn-primary:hover img, .btn-primary:focus img {
  -webkit-filter: invert(0);
  filter: invert(0);
}

.chatWidget .form-group {
  margin-top: 1rem;
  position: relative;
}

.chatWidget .form-group .form-control {
  font-size: 14px;
}

.chatWidget .form-group i {
  position: absolute;
  right: 15px;
  top: 12px;
  color: #ddd;
}

.chatWidget ::-webkit-scrollbar {
  width: 4px;
}

.chatWidget ::-webkit-scrollbar-track {
  background: #ffffff; 
}
 
.chatWidget ::-webkit-scrollbar-thumb {
  background: #ddd; 
}

.chatList {
  max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 250px);
}

.chatList .media {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.chatList .media h5 {
  font-size: 15px;
  margin-bottom: 0;
}

.chatList .media p {
  font-size: 13px;
  margin-bottom: 0;
}

span.userIcon {
  position: relative;
  margin-right: 1rem
}
span.userIcon .badge {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  padding: 0;
  display: inline-block;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.userIcon .badgeOnline {
  background: green;
}

.userIcon .badgeOffline {
  background: orange;
}

.messageBox .media-body {
  background: #f9f9f9;
  padding: 1rem;
  max-width: 60%;
  border-radius: 10px;
  margin-bottom: 1rem;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
}

.messageBox .media-body:hover {
  background: #eeeeee;
  box-shadow: 0 0 30px -18px #888888;
}

.messageBox .userReply .media-body {
  background: #31cab9;
  position: relative;
  color: #fff
}

.messageBox .media-body h5, .messageBox .media-body p {
  margin-bottom: 0;
}

.chatBox {
  height: 100%;
  position: relative;
}

.messageBox {
  height: calc(100vh - 230px);
  padding-bottom: 3rem;
  overflow-y: auto;
  max-height: 100%;
}

.replyBox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f9f9f9;
  padding: 1rem;
}

.replyBox form .btn-primary {
  margin-left: auto
}

.replyBox form input {
  border: 0;
  color: #272727;
  padding: 0 15px;
  height: 44px;
  width: calc(100% - 128px);
  margin-right: 1rem;
  font-size: 14px;
}

.replyBox label {
  margin: 0 1rem 0 0;
  background: #2B333B;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
/*-=-==-=
Media Css
-=-=-=-=-*/
@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}
}
@media (min-width:992px) and (max-width:1199px) {
  .card .card-title {
    font-size: 15px;
  }
  .usersTable .table tbody td:first-child, .usersTable .table thead th:first-child {
    min-width: 290px;
  }
  .usersTable .table thead>tr>th:nth-child(3) {
    min-width: 115px;
  }
}
@media (max-width: 991px) {
  .my-nav-sec.navbar-nav {
    display: flex;
    padding-left: 0;
    list-style: none;
    text-align: center;
    width: auto;
    flex-direction: row;
    margin: 0;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap
  }

  .my-nav-sec.navbar-nav .orderlast {
    order: 13;
    flex: 0 0 100%
  }

  .my-nav-sec .dropdown.nav-item {
    margin-top: 0;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    margin: 0
  }

  .navbar {
    padding-left: 1rem
  }
  .navbar .navbar-nav .nav-item .dropdown-menu {
    background: #ffffff
  }
  .main-panel{
    height: auto;
  }
  .usersTable .table thead>tr>th:nth-child(3) {
    min-width: 115px;
  }
  .imageUpload {
    width: 160px;
    height: 160px;
  }
}

@media screen and (max-width:575px) {
  .my-nav-sec.navbar-nav {
    width: 100%;
  }
  .form-detail .form-group p span {
    display: block;
  }

  .project-section {
    display: -webkit-box !important;
  }

  .user-detail-section .projectTitle {
    display: -webkit-box !important;
    width: 100%;
    margin: 0 auto;
  }

  .user-detail-section .manageActions {
    width: 60%;
  }

  .headTag button {
    margin-left: 15px !important;
  }

  .sidebar {
    overflow: hidden;
  }

  .swal-modal {
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }

  .swal-footer {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .navbar .navbar-nav .notification {

    right: 63px;
  }

  .headTag {
    margin: 0 0 15px;
    justify-content: space-between
  }

  #basic-navbar-nav .my-nav-sec.navbar-nav {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    text-align: center;
    width: 100%;
    margin: 0 auto !important;
    justify-content: space-between;
  }

  .navbar .navbar-nav .nav-item .searchInput {
    min-width: 170px;
    width: 170px;
  }

  .navbar .navbar-nav .notification {
    left: 50px;
  }

  .dashboard-mobile {
    width: 100%;
    text-align: center;
    display: flex;
    margin: 0 auto;
  }

  .dashboard-mobile .fas.fa-ellipsis-v {
    width: 14px;
    height: 13px;
  }

  .card-stats .card-body {
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .card .card-title {
    font-size: 15px;
  }

  .projectTitle {
    flex-flow: unset;
  }

  .media {
    display: flex;
  }

  .media-body {
    width: 78%;
    vertical-align: -moz-middle-with-baseline;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 28%;
  }

  .react-datepicker__input-container input {
    width: 14rem;
  }

  .react-autosuggest__input {
    width: 100%;
  }

  .demo-date {
    width: 50%;
  }

  .heading-text {
    text-align: right;
    width: 137px;
  }

  .second-section {
    margin-top: 1rem;
  }

  .main-panel > .content {
    padding: 30px 0px;
  }

  .main-panel{
    height: auto;
  }

  .headTag h3, .headTag .btn-primary {
    font-size: 14px;
  }

  .headTag h3 span {
    font-size: 12px;
  }
}

.dhx_message-container--in-body {
    position: fixed;
    display: none;
}

.dhx_alert {
	display: none !important;
	
}

.dhx_alert__overlay {

	display: none;
}

.dhx_638523928_message.dhx_message.dhx_widget {
 
  display: none !important;
}

.fisrt-section {
  flex: 0 0 50%;
  max-width: 38%;
  margin-left: 9rem;
}
.selected-translator{
  background-color: #c1cad2; 
}

.zcf-more::before {
    content: "\e909";
}
.moreActions {
  position: absolute;
  right: 0;
  top: 14px;
  bottom: 0;
  opacity: 0;
  margin: auto;
}
.messageBox .media-body:hover .moreActions {
  opacity: 1;
}
.messageBox .userReply .media-body:hover {
  background: #2bb0a2;
  box-shadow: 0 0 30px -15px #000;
}
.messageBox .media-body .moreActions #dropdown-basic, .messageBox .media-body .moreActions #dropdown-basic:focus, 
.messageBox .media-body .moreActions #dropdown-basic:hover, .messageBox .media-body .moreActions #dropdown-basic:focus-visible {
  color: #000000;
  border: 0;
  background: none;
  box-shadow: none;
  outline: none
}
.messageBox .userReply .media-body .moreActions #dropdown-basic, .messageBox .userReply .media-body .moreActions #dropdown-basic:focus, 
.messageBox .userReply .media-body .moreActions #dropdown-basic:hover, .messageBox .userReply .media-body .moreActions #dropdown-basic:focus-visible {
  border: 0;
  color: #fff;
  background: none;
  box-shadow: none;
  outline: none
}
.moreActions #dropdown-basic:after {
  display: none
}
