@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0
}

body {
    margin: 0;
    font-size: 14px;
    background: #fafdfb;
    color: #272727;
    font-family: Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.App,
.formMain,
body,
html {
    height: 100vh
}

img {
    max-width: 100%
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style: none
}

a:focus,
a:hover,
button:focus {
    text-decoration: none;
    color: inherit;
    outline: 0;
    box-shadow: none
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    color: #2e99fb;
    font-family: Montserrat, sans-serif
}

.fa-x {
    font-size: 1.395em !important
}

.swal-button {
    padding: 7px 19px;
    border-radius: 5px;
    font-size: 12px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .3)
}

.swal-button--confirm {
    padding: 7px 19px;
    border-radius: 5px;
    font-size: 12px;
    background-color: #31cab9;
    color: #fff !important;
    ;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .3)
}

.swal-button--confirm:hover {
    background-color: #31cab9;
    color: #fff
}

.pagination {
    border-radius: 0;
    justify-content: center;
    padding: 0;
    margin-top: 1rem
}

.pagination>li {
    display: inline-block;
    padding-left: 0
}

.pagination>li {
    list-style: none;
    border: 0;
    margin: 0 .1rem
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 4px 10px;
    line-height: 1.42857143;
    text-decoration: none;
    background: #080015;
    color: #fff !important;
    border: 0;
    margin-left: 0
}

.pagination>li.active>a {
    background: #2e99fb
}

.pagination>li>a:hover {
    background-color: #2e99fb;
    color: #fff
}

.pdf-viewer {
    width: 600px;
    height: 700px
}

.navbar {
    padding: .5rem 0
}

.navbar-expand {
    background: #2e99fb
}

.topbar #sidebarToggleTop {
    color: #fff;
    padding: 0
}

.sidebar-light .sidebar-brand {
    color: #fafafa;
    background-color: #2e99fb
}

.sidebar .sidebar-brand .sidebar-brand-text {
    text-transform: uppercase;
    font-size: 23px;
    text-shadow: 2px 2px 2px #66666670;
    letter-spacing: 1.5px
}

.topbar .topbar-divider {
    width: 0;
    border-right: 1px solid #e3e6f0;
    height: 0;
    margin: 0
}

footer.sticky-footer {
    padding: 1rem 0
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0
}

.breadcrumb-item a {
    color: #2e99fb
}

.sidebar .sidebar-heading {
    margin-bottom: 1rem
}

.sidebar-light .nav-item .nav-link:hover {
    background: #2e99fb;
    color: #fff
}

.sidebar-light .nav-item .nav-link:hover i,
.sidebar-light .nav-item.active .nav-link:hover i {
    color: #fff
}

.sidebar-light .nav-item .nav-link[data-toggle=collapse]:hover::after {
    color: #fff
}

.sidebar .nav-item .collapse,
.sidebar .nav-item .collapsing {
    margin: 0
}

.sidebar .nav-item .collapse .collapse-inner,
.sidebar .nav-item .collapsing .collapse-inner {
    padding: 0;
    margin: 0 0 1rem 0;
    box-shadow: none;
    background: 0 0
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
    padding: .5rem 1rem;
    margin: 0 .5rem;
    display: block;
    color: #fff;
    text-decoration: none;
    border-radius: 0;
    white-space: nowrap;
    background: #58adfb
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
    color: #fff;
    background: #272727
}

.sidebar .nav-item .nav-link {
    padding: .5rem 1rem
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
    padding: 0;
    border: 0
}

.navbar-expand .navbar-nav .dropdown-menu:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -20px;
    right: 22px
}

.dropdown-divider {
    display: none
}

.dropdown-item {
    padding: .5rem;
    border: 0;
    border-bottom: 1px solid #eee
}

.custom-select,
.form-control {
    font-size: 13px
}

.card-header {
    border: 0
}

.card-header:first-child {
    border: 0
}

.card .table td,
.card .table th {
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 14px;
    vertical-align: middle
}

.card .table th {
    font-weight: 600
}

.card .table td .fa.fa-pencil-square {
    color: #2e99fb
}

.card .table th:nth-child(5),
.card .table th:nth-child(7) {
    min-width: 162px
}

.table thead th {
    border-bottom: 1px solid #dee2e6
}

/* .table td .badge{font-weight:600;height:20px;line-height:13px} */
.modal-header {
    padding: .5rem 1rem
}

.modal-body {
    max-height: 450px;
    overflow-y: auto
}

.modal-body::-webkit-scrollbar {
    width: 5px
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: #d3d3d3
}

.card .table td i {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    border-radius: .3rem
}

.card .table td .fa-trash-o {
    background: red;
    color: #fff
}

.card .table td .fa-pencil {
    background: #0468d3;
    color: #fff
}

.form-group ul {
    display: flex;
    flex-flow: row wrap
}

.form-group ul li {
    border: 2px solid #2e99fb50;
    background: #2e99fb0f;
    color: #2e99fb;
    border-radius: .25rem;
    -webkit-border-radius: .25rem;
    -moz-border-radius: .25rem;
    -ms-border-radius: .25rem;
    -o-border-radius: .25rem;
    margin-right: .5rem;
    margin-bottom: .5rem;
    padding: .2rem .5rem;
    cursor: pointer
}

.swal-modal {
    width: 100%;
    max-width: 850px;
}

.swal-content {
    max-height: 450px;
    overflow-y: auto;
}

.swal-content::-webkit-scrollbar {
    width: 5px
}

.swal-content::-webkit-scrollbar-thumb {
    background-color: #d3d3d3
}

.swal-content span {
    display: inline-block;
    margin: 20px 15px 0 0;
    text-align: left;
    cursor: pointer
}

.swal-content span:first-child {
    margin-left: 15px
}

.swal-content span svg {
    width: 40px;
    height: auto;
    fill: #2e99fb
}

#wrapper #content-wrapper {
    height: 100vh;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out
}

.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100vh;
    width: 14rem;
    background-color: #fff;
    -webkit-box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15);
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15);
    z-index: 111;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out
}

.sidebarClose {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transition: all .3s ease-in-out 70ms;
    -webkit-transition: all .3s ease-in-out 70ms
}

.sidebarOpen~#content-wrapper {
    margin-left: 14rem
}

.uploadedBy tr {
    border-bottom: 1px solid #dee2e6 !important
}

.uploadedBy tr:last-child {
    border-bottom: 0 !important
}

.profilePage {
    padding: 2rem 0
}

.profilePage form {
    background: #fff;
    box-shadow: 0 0 50px -20px rgba(0, 0, 0, .2);
    padding: 1.5rem;
    border-radius: .3rem;
    -webkit-border-radius: .3rem;
    -moz-border-radius: .3rem;
    -ms-border-radius: .3rem;
    -o-border-radius: .3rem
}

.profilePage form input {
    font-size: small
}

.profilePage .fileUpload {
    text-align: center;
    display: block;
    cursor: pointer;
    border: 2px dashed rgba(0, 0, 0, .2);
    padding: 2rem;
    margin: 0
}

.profilePage .fileUpload svg {
    width: 220px;
    height: auto;
    margin: auto auto 1rem;
    display: block
}

.inputs input {
    border: 1px solid #ddd;
    font-size: 13px;
    padding: 8px 12px;
    width: 100%;
}

@media (max-width:767px) {
    .sidebarClose {
        transform: none;
        -webkit-transform: none
    }

    .sidebarOpen {
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%)
    }

    .sidebarOpen~#content-wrapper {
        margin-left: 0
    }
}

@media (max-width:575px) {
    .profilePage .fileUpload {
        margin-bottom: 1.2rem
    }
}

/* .dhx_message-container--in-body {
    position: fixed;
    display: none;
} */
.button-create-project {
    display: flex;
    justify-content: end;
    position: absolute;
    bottom: 18px;
    right: 25px;

}

.button-create-project button {
    background-color: #31cab9 !important;
    opacity: 1;
}

.project-heading h5 {
    color: #000;
    font-weight: 400;
}

.project-heading .form-control {
    height: 45px;
}

.project-heading .date-deu {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    height: 35px;
    padding-left: 10px;
    padding-top: 4px;
    color: #333333cc !important;
    border: 1px solid #33333370 !important;
}

.due-span {
    margin-right: 40px;
    font-size: 16px;
}

.project-heading .form-detail .form-group .fa-calendar {
    border: 2px dashed #33333370 !important;
    color: #33333370 !important;
}

/* .checkbox-select
{

} */
.form-detail .form-check .form-check-label {
    padding-left: 7px;
    line-height: 20px !important;
    font-style: italic;
    font-weight: 400;
    color: #333333cc !important;
    font-size: 16px !important;
}

.select-lang {
    font-size: 16px !important;
    text-transform: capitalize !important;
}

.next-top-row {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
}

.button-script {
    border: none !important;
    font-size: 13px;
    margin-top: 0;
}

.button-english {
    /* margin-left: 10px; */
    border-left: 1px solid #c1c1c1 !important;
}

.button-english .fa-arrow-right {
    padding-left: 20px;
}

.fa-angle-right {
    padding-right: 5px;
}

.select-box-next {
    width: 200px;
    display: flex;
    justify-content: flex-start;
}

.top-next-buttons {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.button-right-next button {
    border-color: #2a66a7 !important;
    color: #2a66a7 !important;
    font-size: 14px !important;
}

.button-right-next {
    margin: 0 0 0 auto;
}

.top-next-buttons input {
    width: 120px;
    border: none;
    color: #333;
    padding-left: 15px;
}

.top-next-buttons input::placeholder {
    font-size: 14px;
    color: #888888;
}

.form-control {
    color: #888888;
    opacity: 0.8;
}

.progress-bar {
    background-color: #a2e6d9 !important;
}

.progressbar-row {
    padding: 0;
}

.text-next-number {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 15px;
    color: #888888;
    opacity: 0.8;
}

.number-next {
    font-size: 22px;
    color: #888888;
    opacity: 0.8;
}

.num-nine {
    color: #004a99 !important;
    font-weight: 500;
}

.border-bottom {
    border-bottom: 3px solid #004a99 !important;
}

.nav-tabs .nav-link.active {
    color: #004a99 !important;
    font-weight: 500;
    border-bottom: 3px solid #004a99 !important;
}

.nav-tabs .nav-link {
    font-size: 14px;
    color: #888888;
    opacity: 0.8;
    font-weight: 500;
}

.nav-tabs .nav-link {
    margin-bottom: 0px;
    border: none !important;
    width: 33.33%;
    text-align: center;
}

.tab-section-col {
    padding: 0;
}

.card-header-tabs {
    margin: 0;
}

.tab-content-next {
    padding-top: 15px;
}

.multipleSelection {
    width: 300px;
    background-color: #BCC2C1;
}

.selectBox {
    position: relative;
}

.selectBox select {
    width: 100%;
    font-weight: bold;
}

.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#checkBoxes {
    display: none;
    border: 1px #8DF5E4 solid;
}

#checkBoxes label {
    display: block;
}

#checkBoxes label:hover {
    background-color: #4F615E;
}

.filter-button button {
    border: 1px solid #888888 !important;
    color: #888888 !important;
    opacity: 0.8 !important;
    padding: 8px 20px !important;
    margin-right: 10px;
}

.serch-icon {
    position: absolute;
    top: 12px;
    padding-left: 10px;
    color: #888888 !important;
}

.filter-button {
    display: flex;
    /* padding-left: 25px; */
}

.serch-bar-filter {
    margin-left: 10px;
    width: inherit;
}

.form-control::placeholder {
    /* padding-left: 20px; */
    color: #888888 !important;
    font-size: 14px;
}

.serch-bar-filter .form-control {
    background-color: #FFFFFF;
    border: 1px solid #c9c1c1 !important;
    border-radius: 4px;
    color: #565656;
    padding: 8px 9px;
    height: 44px;
    padding-left: 30px;
    box-shadow: none;
}

.select-text-box-tab {
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;
}

.select-box-label {
    margin-right: 5px;
    margin-top: 5px;
}

.tab-content-next-sec {
    padding-top: 25px;
    padding-left: 10px;
}

.unsaved {
    text-transform: uppercase;
    color: #888888 !important;

}

/* .tabs-section-right
{
    padding-left: 5px;
} */

.tabs-section-right .nav-tabs .nav-link {
    margin-bottom: 0px;
    width: 25%;
    text-align: center !important;
}

.tabs-section-right .nav-tabs .nav-link {
    font-size: 12px !important;
    padding-right: 7px;
    padding-left: 0;
    padding-top: 0;
}

.tabs-section-right .nav-tabs .nav-link.active {
    color: #004a99 !important;
    font-weight: 500;
    border-bottom: 2px solid #004a99 !important;
}

.select-box-label .form-control {
    padding: 8px 9px !important;
}

.suggation-sec button {
    border-color: #2a66a7 !important;
    color: #2a66a7 !important;
    font-size: 12px !important;
}

.suggation-sec {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0;
}

.suggation-sec p {
    font-size: 12px;
}

.logo-img-sec {
    padding-right: 0;
    margin: 0 0 0 auto;
}

.logo-text-sec p {
    font-size: 12px;
    color: #888888 !important;
    opacity: 0.8 !important;
    display: flex;
    justify-content: inherit;
    align-items: center;
}

.data-text-sec-user {
    color: #2a66a7 !important;
    margin-left: 7px;
    margin-right: 7px;
}

.tab-second-sec-padding {
    padding-right: 30px;
}

.text-yetext {
    padding-right: 0;
}

.text-secon-tab-span {
    color: #2a66a7 !important;
}

.text-grey {
    font-size: 12px;
    color: #888888 !important;
    opacity: 0.8 !important;
}

.text-yetext p {
    font-size: 12px;
    color: #5c5656 !important;
}

.TM {
    background-color: #888888;
    color: #fff;
    width: 40px;
    height: 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8 !important;
    margin: 0 0 0 auto;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: transparent;
}

.text-bold-text-section p {
    font-size: 13px;
    color: #888888;
    margin-bottom: 5px;
}

.edit-context button {
    border-color: #2a66a7 !important;
    color: #2a66a7 !important;
    font-size: 14px !important;
}

.edit-context {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
    margin-top: 0px;
}

.edit-context-text p {
    font-size: 13px;
    color: #888888;
}

.edit-context-text span {
    font-weight: 700;
}

.edit-context-dropdown button {
    background-color: #2a66a7 !important;
    opacity: 1;
    border: none !important;
    color: #fff !important;
    padding: auto !important;
    font-size: 13px;
}

.edit-context-dropdown {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
}

.edit-context-text-copy .fa-copy {
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 20px;
}

.edit-context-text-copy {
    margin-bottom: 20px;
}

.pagination>li>a {
    color: #000 !important;
}

.edit-context {
    border-top: 1px solid #c9c1c1;
    border-bottom: 1px solid #c9c1c1;
    padding-top: 10px;
    padding-bottom: 10px;
}

.text-section-border {
    border-right: 1px solid #c9c1c1;
}

.table-text-data {
    display: flex;
    justify-content: space-between;
}

.table-text-data p {
    font-size: 12px;
    margin-bottom: 7px;
}

i.far.fa-square {
    color: #2a66a7;
    font-size: 18px;
    /* font-weight: 700; */
    cursor: pointer;
}

i.fas.fa-circle {
    color: #8DF5E4;
    font-weight: 700 !important;
}

.table-row-data {
    padding-left: 25px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 10px;
}

.circle-icon {
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.secont-table-dada {
    margin-left: 10px;
}

.data-text-f {
    margin: 0 auto 0 0;
}

.sqaure-icon {
    margin-left: 15px;
}

.data-number {
    display: flex;
}

.table-data {
    /* border-top: 1px solid #c9c1c1; */
    border-bottom: 1px solid #c9c1c1;
    padding-top: 10px;
    padding-bottom: 10px;
}

.background-color-unseved {
    background-color: #e7e4e4;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    margin-bottom: 14px;
}

.copy-icon-border {
    border: 1px solid #c9c1c1;
    padding: 15px;
}

textarea.form-control {
    border: 1px solid #c9c1c1 !important;
    margin-top: -3px;
}

i.fas.fa-reply-all {
    color: #8DF5E4;
}

.card .table thead th:first-child {
    min-width: auto !important;
}

.select-table-mul {
    overflow-x: scroll;
    padding-left: 0;
    overflow-y: scroll;
    height: 600px;
}

/* .third-colom-sec
{
    padding-left: 25px;
} */
.all-select-check-box .form-check-input {
    margin-left: 0 !important;
    margin-top: 0 !important;
}

.table-check-m .table>tbody>tr {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.data-ce {
    display: contents;
}

.projectTable .table tbody tr td {
    width: auto !important;
}

.form-check-input {
    position: relative;
    margin-bottom: 10px;
    margin-left: 0;
    margin-top: 10px;
}

.table-check-m .table>tbody>tr>th {
    padding: 12px 0 !important;
}

.tablecheckbox-t {
    overflow: scroll;
    padding-left: 0;
    height: 500px;
    padding-right: 0;
}

.form-detail .form-check {
    margin-right: 1rem;
    flex: 0 auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-bold-script {
    font-weight: 600;
}

.back-icon-script {
    font-size: 25px;
    cursor: pointer;
}

.submit-project-button button {
    background-color: #31cab9 !important;
}

.script-data-edit {
    display: flex;
}

.edit-script {
    border: 1px solid #000;
}

.table-main-script table {
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    /* border: 1px solid #dddddd; */
    text-align: left;
    padding: 8px;
}

.fileDrag svg {
    width: 8rem !important;
    /* text-align: center; */
    display: flex;
    margin: 0 34%;
}

.fileDrag h3 {
    font-size: 17px !important;
    text-align: center;
}

.fileDrag {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 0 auto;

}

.fileDrag p {
    text-align: center;
}

.fileDrag button {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.image-drag-drop {
    position: relative;
}

.image-drag-drop img {
    width: 143px;
    margin: 0 27%;
}

.image-drag-drop .fa.fa-times {
    position: absolute;
    top: 0;
    right: 0;
    background: #333;
    width: 25px;
    height: 25px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    z-index: 9999;
}

.upload-file-main-wrap {
    position: absolute;
    top: 80px;
    right: 20px;
    width: 300px;
}

.colomn-wrap {
    width: 60%;
}

.button-wraper {
    display: flex;
    justify-content: end;
    position: absolute;
    right: 40px;
    bottom: 20px;
}

.button-wraper button {
    background-color: #31cab9 !important;
}

.wraper-script-translate {
    padding-left: 15px;
    padding-top: 15px;
}

.script-data-translate {
    border-bottom: 1px solid #BCC2C17A;
    padding-top: 15px;
    cursor: pointer;
}

.bg-salmon {
    background-color: #BCC2C1;
}

.search-filter {
    margin-top: 10px;
    width: 100%;
}

.script-main-wrap {
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DEE1E7 !important;
}

.script-main-wrap col {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.select-language-wrap {
    display: flex;
}

.script-main-wrap p {
    font-size: 13px !important;
    line-height: 2 !important;
    margin-bottom: 0;
}

.script-main-wrap:nth-child(even) {
    background-color: rgba(0, 115, 196, 0.08);
}

/* .checkbox-style-wrap
{
    display: flex;
    justify-content: space-between;
} */
.checkbox-pointer {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
}

.checkbox-style-wrap p {
    display: flex;
    /* flex-direction: column!important; */
}

.search-filter i {
    position: absolute;
    left: 24px;
    top: 22px;
    color: #9f9898;
    font-size: 16px;
}

/* .search-filter .form-control::placeholder
{
  padding-left: 20px;
} */
.search-filter .form-control {
    padding-left: 30px;
}

.tab-second-sec-padding button {
    height: 31px !important;
    font-size: 12px !important;
    padding: 4px 7px !important;
}

.tab-second-sec-padding col {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.tab-second-sec-padding {
    padding-left: 15px;
    display: contents;
    padding-bottom: 30px;
}

.tab-content-next-sec .logo-text-sec {
    display: flex;
    justify-content: end;
    padding-left: 10px;
}

.project-name-wrap {
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -16px;
}

.edit-icon-project {
    font-size: 17px;
    font-weight: 900;
}

.edit-script-translate {
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-script-btn {
    width: 40px;
    display: flex;
    justify-content: end;
}

.update-btn-sec button {
    margin-right: 10px;
    background-color: #31cab9 !important;
}

.comment-section textarea {
    height: 120px !important;
    width: 60% !important;
    margin-top: 5px;
}

.script-changes-btn button {
    background-color: #31cab9 !important;
    color: #fff !important;
    font-size: 14px;
    border: none !important;
    margin-bottom: 10px;
}

.script-changes-btn button:hover {
    background-color: #31cab9 !important;
    color: #fff !important;
}

.edit-context-dropdown button {
    background-color: #31cab9 !important;
    color: #fff !important;
    font-size: 14px;
    border: none !important;
}

.button-script-delete {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
}

.button-script-delete button {
    margin-bottom: 20px;
}

.script-changes-btn {
    display: flex;
    justify-content: flex-start;
}

.submit-btn-edit-translate {
    display: flex;
    justify-content: end;
}

.img-edit-translate {
    display: flex;
    padding-left: 30px;
    padding-top: 20px;
}

.img-edit-translate .imageUpload {
    width: 220px;
    height: 220px;
}

.img-add-translate {
    display: flex;
    padding-left: 30px;
}

.img-add-translate .imageUpload {
    width: 210px;
    height: 210px;
}

.form-control {
    border: 1px solid #9f9898 !important;
    color: #333 !important;
}

.form-control::placeholder {
    color: #333 !important;
}

.card label {
    color: #333 !important;
}

.languages-lebal label {
    opacity: 0.8;
}

.languages-lebal .inputs input {
    border: 1px solid #9f9898 !important;
}

.background-add-translater {
    height: 65vh;
}

.submit-btn-edit-translate button {
    border-color: #31cab9 !important;
}

.chatWidget .form-group i {
    color: #9f9898 !important;
}

.chatList {
    border-right: 1px solid #9f9898 !important;
    padding-right: 15px;
}

.lanuage-contury h3 small {
    color: #383131 !important;
}

.swal-button:not([disabled]):hover {
    background-color: #31cab9;
}

.data {
    background-color: red;
}

.view-translate-badge .imageUpload .badge {
    right: 28px !important;
}

.profile-img-badge .online.badge {
    width: 14px;
    height: 14px;
    display: inline-block;
    padding: 0;
    position: absolute;
    right: -3px;
}

.autosuggest-input input {
    padding: 7px;
    margin-top: 5px;
    width: 100%;
}

.autosuggest-input label i {
    font-size: 17px;
    border: 1px solid;
    width: 35px;
    height: 35px;
    line-height: 32px;
    text-align: center;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    border: 2px dashed #33333370 !important;
    color: #33333370 !important;
    margin-right: 5px;
}

.autosuggest-input {
    display: flex;
    /* justify-content: space-between; */
    align-items: baseline;
}

.react-autosuggest__container {
    width: 100% !important;
}

.select-lang {
    margin-right: 10px;
    width: 295px;
}

.date-section-m {
    display: flex;
    justify-content: space-between;
}

.tasks-add {
    margin-top: 15px;
}

.tasks-add span {
    display: flex;
    justify-content: space-between;
}

.projectTable {
    padding-top: 20px;
}

.date-section-m span {
    min-width: 200px;
}

.autosuggest-input ul {
    display: inherit !important;
}

.start-time input {
    width: 100px;
    height: 33px;
    font-size: 14px;
    /* padding-left: 10px; */
    text-align: center;
}

.start-time input::placeholder {
    text-align: center;
}

.end-time input {
    width: 100px;
    height: 33px;
    font-size: 14px;
    /* padding-left: 10px; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.end-time input::placeholder {
    text-align: center;
}

.end-time {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.start-time {
    display: flex;
    align-items: center;
    justify-content: center;
}

.time-s {
    width: 100px;
    margin-top: 5px;
    margin-left: 10px;
}

.end-s {
    width: 100px;
    margin-top: 5px;
    /* margin-left: 10px; */
}

.start-time-text {
    display: flex;
    align-items: end;
    font-size: 13px;
    text-transform: capitalize;

}

/* .top-next-buttons button
{
    margin-top: -11px;
} */
.date-time-w {
    padding: 0 !important;
}

.date-time-picker input {
    width: 85px;
    font-size: 12px;
    margin-left: 4px;
}

.time-w {
    padding-left: 0 !important;
}

.date-time-picker {
    display: flex;
    justify-content: space-between;
}

.add-btn-row {
    display: flex;
    justify-content: end;
    margin: 0 0 0 auto;
    width: 218px;
}

.add-btn-row button {
    background-color: transparent !important;
    border: none !important;
    color: #31cab9 !important;
}

.plus-icon {
    border: 1px solid #31cab9;
    padding: 0 5px;
    margin-right: 5px;
    font-weight: bold;
    font-size: 16px;

}

.massage-box-pb {
    margin-bottom: 7rem !important;
}

.massage-box-pb h5 {
    color: #fff !important;
}

.selected-translator {
    padding: 12px;
}

.circle-language i.fas.fa-circle {
    color: red !important;
}

.circle-language {
    margin-right: 5px;
}

.main-wraper-f {
    display: flex;
    margin-bottom: 15px;
}

.second-wrap {
    border: 2px solid #494848;
    height: 52px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.box-presentage p {
    font-size: 12px;
    margin-bottom: 0;
    text-align: center;
}

.pre-p {
    color: #2e99fb;
    font-weight: 600;
}

.text-wrap-s p {
    font-size: 12px;
    margin-bottom: 5px;
    word-wrap: anywhere;
}

.text-dark {
    color: #818386 !important;
}

.text-wrap-s {
    padding-left: 5px;
}

.custom-switch {
    padding-left: 2.25rem;
    /* position: absolute; */
    top: 10px;
    right: 38px;
    left: 43%;
}

.search-top-main-wrap {
    margin: 0 auto;
    width: 490px;
}

.madia-m-w {
    display: flex;
    /* justify-content: space-between; */
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 13px;
}


.search-main-section-w.form-group {
    display: flex;
}

.search-main-section-w button {
    margin-left: 15px;
    height: 40px;
}

.search-icon-m i {
    position: absolute !important;
    right: 25% !important;
}

.madia-m-w h5 {
    width: 150px
}

.heading-wraper-serch {
    display: flex;
}

.language-search-m-w {
    width: 150px;
}

.heading-wraper-serch h5 {
    color: #31cab9;
}

.card-main-wraper-search .card {
    height: 90vh !important;
}

.edit-context-dropdown button {
    font-size: 12px !important;
    display: flex;
    justify-content: flex-start;
}

.script-changes-btn button {
    font-size: 12px !important;
    display: flex;
    justify-content: flex-start;
}

.wrap-language-index {
    display: flex;
}

.left-index {
    margin-right: 15px;
}

.google-Drive-btn {
    position: absolute;
    /* top: 47%; */
    right: 3%;
    text-align: center;
    margin-top: 300px;
}

.fileNameMainWraper {
    margin-top: 20px;
}

.google-Drive-btn h5 {
    margin-top: 20px;
}

.AllowedMainWraper {
    margin-top: 20px;
}

.google-picker {
    font-size: 14px !important;
    line-height: 2;
}

.image-drag-drop {
    position: relative;
    border: 1px solid #b8b8b8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    /* margin-bottom: 49px; */
}

span.trash_icon {
    background: #333;
    width: 40px;
    color: #fff;
    padding: 2px 5px;
}

.modelDownloadFormate {
    display: flex;
    flex-direction: column;
}

.modelDownloadFormate button {
    width: 120px;
    margin-bottom: 10px;
    background-color: #31cab9 !important;
    color: #fff !important;
    cursor: pointer;
}

.pdfFormetermainwraper a {
    color: #fff !important;
}

.projectNamemain {
    margin-left: 60px;
}

.edit-project-btnMain-wraper {
    display: flex;
    align-content: center;
    justify-content: flex-start;
}

.projectIcon-se-wraper img {
    width: 37px;
    height: 37px;
    border-radius: 100px;
    max-width: 37px;
    max-height: 37px;
}

.projectIcon-main-wraper img {
    width: 37px;
    height: 37px;
    border-radius: 100px;
    max-width: 37px;
    max-height: 37px;
}

.main-comment-list-wraper {
    margin-bottom: 10px;
    margin-right: -15px;
}

.main-comment-text {
    margin-left: 10px;
    margin-right: 10px;
    width: 182px;
    font-size: 12px;
    text-transform: capitalize;
    line-height: 3;
}

.comment-secion-main .heading-text {
    font-size: 10px;
    margin-left: 10px !important;
    line-height: 3;
}

span.projectIcon-main-wraper.mr-3 {
    display: flex;
}

.comment-main-text-edit-project {
    width: 400px;
    text-align: left;
    text-align: left;
    font-size: 12px;
    text-transform: capitalize;
    word-wrap: anywhere;
    line-height: 3;
}
.react-autosuggest__suggestions-container{
    margin-top: 12px;
}
.comment-secion-main {
    height: 400px;
    overflow-y: scroll;
}

.bottam_scroll{
    height: 400px;
    width: 500px
}
.search-icon-m {
    width: 100%;
}
.status-table-tran {
	display: flex;
	justify-content: center;
}
.projectTable .table thead > tr > th{
    font-size: 14px!important;
}